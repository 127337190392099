<template>
<div>
    <pubTable :table="table"></pubTable>
    <div class="block">
        <el-pagination @size-change="changeSize" @current-change="changePage" :current-page="page" :page-sizes="[10, 20, 30, 50 ,100]" :page-size="size" layout="total, sizes, prev, pager, next, jumper" :total="count">
        </el-pagination>
    </div>
</div>
</template>

<script>
export default {
    components: {
        pubTable: () => import("@/components/public/table"),
    },
    async created() {
        await this.request();
    },
    methods: {
        async request() {
            this.$req.url = this.reqConf.stat;
            this.$req.type = 'post';
            this.$req.param = {
                token: window.sessionStorage.getItem("token"),
                page: this.page,
                size: this.size,
                gameId: this.gameId,
                isAllRoom: 0
            };
            const res = await this.$req.bind(this);
            this.table.fieldData = res.data.field;
            this.table.tableData = this.setRoomName(res.data.list);
        },
        setRoomName(data) {
            return data.map(item => {
                let roomName = '';
                switch (item.roomId) {
                    case 1:
                        roomName = '体验场';
                        break;
                    case 2:
                        roomName = '初级场';
                        break;
                    case 3:
                        roomName = '中级场';
                        break;
                    case 4:
                        roomName = '高级场';
                        break;
                    case 5:
                        roomName = '大师场';
                        break;
                }
                item.roomId = roomName;
                item.gameId = '聚宝盆';
                return item;
            });
        },
        async changeSize(data) {
            this.size = data;
            await this.request();
        },
        async changePage(data) {
            this.page = data;
            await this.request();
        }
    },
    data() {
        return {
            gameId: 5,
            count: 100,
            page: 1,
            size: 20,
            table: {
                fieldData: [],
                tableData: []
            }
        }
    },
};
</script>

<style scoped>
.block {
    margin-top: 25px;
}
</style>
