<template>
<div>
    <el-select style="width:120px" v-model="gameId" placeholder="请选择">
        <el-option v-for="item in gameList" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
    </el-select>
    <el-select style="width:160px" v-model="sortName" placeholder="请选择排序">
        <el-option v-for="item in sortList" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
    </el-select>
    <el-input style="width:200px" v-model="roomId" placeholder="厅馆ID,为空表示全部厅馆"></el-input>
    <el-input class="userInput" v-model="userId" placeholder="请输入玩家平台ID,为空表示全部"></el-input>
    开始时间
    <el-date-picker v-model="startDate" type="datetime" placeholder="选择统计开始时间" align="right">
    </el-date-picker>
    结束时间
    <el-date-picker v-model="endDate" type="datetime" placeholder="选择统计结束时间" align="right">
    </el-date-picker>
    <el-button @click="request" style="margin-left: 15px" type="primary">查询</el-button>
    <pubTable :table="table"></pubTable>
    <div class="block">
        <el-pagination @size-change="changeSize" @current-change="changePage" :current-page="page" :page-sizes="[10, 20, 30, 50 ,100]" :page-size="size" layout="total, sizes, prev, pager, next, jumper" :total="count">
        </el-pagination>
    </div>
</div>
</template>

<script>
export default {
    components: {
        pubTable: () => import("@/components/public/table"),
    },
    async created() {
        await this.request();
    },
    methods: {
        async request() {
            this.$req.url = this.reqConf.getPlayerBetList;
            this.$req.type = 'post';
            const startTime = Math.floor((+this.startDate) / 1000);
            const endTime = Math.floor((+this.endDate) / 1000);
            this.$req.param = {
                token: window.sessionStorage.getItem("token"),
                page: this.page,
                size: this.size,
                gameId: this.gameId,
                userId: this.userId,
                roomId: this.roomId,
                sortName: this.sortName,
                startTime,
                endTime
            };
            const res = await this.$req.bind(this);
            // this.table.fieldData = res.data.field;
            this.table.tableData = this.setData(res.data.list);
        },
        setData(data) {
            return data.map(item => {
                let roomId = item.roomId;
                if (item.gameId == 1) {
                    switch (roomId) {
                        case 10102:
                            item.roomId = "体验馆";
                            break;
                        case 10202:
                            item.roomId = "初级馆";
                            break;
                        case 10302:
                            item.roomId = "中级馆";
                            break;
                        case 10402:
                            item.roomId = "高级馆";
                            break;
                        case 10502:
                            item.roomId = "大师馆";
                            break;
                    }
                } else {
                    roomId = item.roomId % 10;
                    switch (roomId) {
                        case 1:
                            item.roomId = "体验馆";
                            break;
                        case 2:
                            item.roomId = "初级馆";
                            break;
                        case 3:
                            item.roomId = "中级馆";
                            break;
                        case 4:
                            item.roomId = "高级馆";
                            break;
                        case 5:
                            item.roomId = "大师馆";
                            break;
                    }
                }
                const gameInfo = this.gameList.find(listItem => {
                    return listItem.value == item.gameId;
                });
                if (gameInfo) {
                    item.gameId = gameInfo.label;
                }
                item.addTime = new Date(item.addTime * 1000).toLocaleString("cn-GB", {
                    hour12: false
                });
                return item;
            });
        },
        async changeSize(data) {
            this.size = data;
            await this.request();
        },
        async changePage(data) {
            this.page = data;
            await this.request();
        },
        async viewPlayer(data) {
            const row = data.row;
            let url = "https://08eplay.livesystem168.com";
            if (row.channel.indexOf("aceonline") != -1) {
                url = "https://aceeplay.livesystem168.com";
            }
            let path = `/?vcode=${row.channel}&roundId=${row.roundId}&gameId=${row.siteGameId}&chairId=${row.chairId}&playerId=${row.agent}`;
            window.open(url + path);
        }
    },
    data() {
        return {
            sortName: "addTime",
            sortList: [{
                value: "addTime",
                label: '时间排序'
            }, {
                value: "payment",
                label: '派彩时间'
            }, {
                value: "betMoney",
                label: '下注排序'
            }, ],
            gameList: [{
                value: 0,
                label: '所有游戏'
            }, {
                value: 1,
                label: '三张扑克'
            }, {
                value: 2,
                label: '倍率21点'
            }, {
                value: 4,
                label: '王牌21点'
            }, {
                value: 5,
                label: '聚宝盆'
            }, {
                value: 6,
                label: '五色宝石'
            }, {
                value: 10,
                label: '赛马'
            }],
            gameId: 0,
            startDate: new Date().setHours(0, 0, 0, 0),
            endDate: new Date(),
            count: 100,
            page: 1,
            size: 20,
            userId: "",
            roomId: "",
            table: {
                summary: true,
                fieldData: [{
                        field: 'btn',
                        label: '查看回放',
                        button: true,
                        cb: this.viewPlayer,
                        width: 90
                    },
                    {
                        field: 'agent',
                        label: '平台玩家ID ',
                        width: 100
                    },
                    {
                        field: 'gameId',
                        label: '游戏ID ',
                        width: 80
                    },
                    {
                        field: 'roomId',
                        label: '厅馆 ',
                        width: 80
                    },
                    {
                        field: 'tableId',
                        label: '桌子 ',
                        width: 60
                    }, {
                        field: 'chairId',
                        label: '椅子 ',
                        width: 60
                    },
                    {
                        field: 'roundId',
                        label: '局号 ',
                        width: 180
                    },
                    {
                        field: 'transactionData',
                        label: '交易号 ',
                        width: 320
                    },
                    {
                        field: 'betMoney',
                        label: '下注金额 ',
                        width: 100
                    },
                    {
                        field: 'payment',
                        label: '派彩金额 ',
                        width: 100
                    }, {
                        field: 'profit',
                        label: '盈利 ',
                        width: 100
                    }, {
                        field: 'addTime',
                        label: '时间',
                        width: 160
                    }

                ],
                tableData: []
            }
        }
    },
};
</script>

<style scoped>
.block {
    margin-top: 25px;
}
</style>
