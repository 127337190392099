<template>
<div>
    <div class="block">
        <span class="demonstration">三张扑克设置</span>
        <el-divider></el-divider>
        <span class="selectTitle">厅馆:</span>
        <el-select v-model="threeCardRoom" placeholder="请选择对象">
            <el-option v-for="item in threeCardRooms" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
        </el-select>
        <div>
            <div>
                <span class="selectTitle">RTP：</span>
                <el-input-number v-model="rtpNum" :min="0" :max="100" label=""></el-input-number>
            </div>
            <div>
                <span class="selectTitle">换牌：</span>
                <el-input-number v-model="changeRate" :min="0" :max="100" label=""></el-input-number>
            </div>
        </div>
        <span class="selectTitle">大牌干预开关:</span>
        <el-select v-model="bigCardKillScoreSwitch" placeholder="请选择对象">
            <el-option v-for="item in switchList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
        </el-select>
        <el-button @click="submitThreeCard" style="margin-left: 15px" type="primary">提交</el-button>
        <el-divider></el-divider>
        <span class="demonstration">21点设置</span>
        <el-divider></el-divider>
        <span class="selectTitle">厅馆:</span>
        <el-select v-model="blackJackRoom" placeholder="请选择对象">
            <el-option v-for="item in blackJackRooms" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
        </el-select>
        <div>
            <span class="selectTitle">庄换牌概率：</span>
            <el-input-number v-model="bjRate" :min="0" :max="100" label=""></el-input-number>
            <el-button @click="submitBlackJack" style="margin-left: 15px" type="primary">提交</el-button>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            threeCardRoom: "10202",
            rtpNum: 0,
            changeRate: 0,
            bigCardKillScoreSwitch: 0,
            threeCardRooms: [{
                    value: "10102",
                    label: "体验馆",
                },
                {
                    value: "10202",
                    label: "初级馆",
                }, {
                    value: "10302",
                    label: "中级馆",
                }, {
                    value: "10402",
                    label: "高级馆",
                }
            ],
            switchList: [{
                    value: 1,
                    label: "开启",
                },
                {
                    value: 0,
                    label: "关闭",
                }
            ],
            blackJackRoom: "12",
            blackJackRooms: [{
                    value: "11",
                    label: "体验馆",
                },
                {
                    value: "12",
                    label: "初级馆",
                }, {
                    value: "13",
                    label: "中级馆",
                }, {
                    value: "14",
                    label: "高级馆",
                }
            ],
            bjRate: 0,
        };
    },
    methods: {
        async submitThreeCard() {
            this.$req.url = this.reqConf.setThreeCardKillScore;
            this.$req.type = "post";
            this.$req.param = {
                token: window.sessionStorage.getItem("token"),
                rtpNum: this.rtpNum,
                changeRate: this.changeRate,
                bigCardKillScoreSwitch: this.bigCardKillScoreSwitch,
                roomId: this.threeCardRoom
            };
            const res = await this.$req.data;
            if (res.code == 0) {
                this.success("提交成功");
            } else {
                this.error(res.msg);
            }
        },
        async submitBlackJack() {
            this.$req.url = this.reqConf.setBalckJackKillScore;
            this.$req.type = "post";
            this.$req.param = {
                token: window.sessionStorage.getItem("token"),
                roomId: this.blackJackRoom,
                rate: this.bjRate
            };
            const res = await this.$req.data;
            if (res.code == 0) {
                this.success("提交成功");
            } else {
                this.error(res.msg);
            }
        }
    },
};
</script>

<style>
.selectTitle {
    font-size: 14px;
    color: #666;
    padding: 0 8px;
}

.inputText {
    font-size: 14px;
    color: #333;
}

.inputClass {
    width: 220px;
    margin-top: 10px;
}

.el-select {
    margin: 10px;
}
</style>
