<template>
<div>
    <pubForm :form="form" @action-click="formClick"></pubForm>
</div>
</template>

<script>
export default {
    components: {
        pubForm: () => import("@/components/public/from"),
    },
    created() {
        this.form = {
            title: "修改密码",
            actionName: "提交",
            list: [{
                    title: "旧密码",
                    type: "password",
                    value: "",
                    field: "oldPassword",
                    args: {
                        width: 300,
                    },
                },
                {
                    title: "新密码",
                    type: "password",
                    value: "",
                    field: "newPassword",
                    args: {
                        width: 300,
                    },
                },
            ],
        };
    },
    methods: {
        async formClick(param) {
            console.log(param);
            this.$req.url = this.reqConf.reset;
            this.$req.type = 'post';
            this.$req.param = {
                token: window.sessionStorage.getItem("token"),
                ...param
            };
            const res = await this.$req.data;
            if (res.code == 0) {
                this.success(res.msg);
            } else {
                this.error(res.msg);
            }
        },
    },
    data() {
        return {
            form: [], //操作表单数据
        };
    },
};
</script>

<style scoped>
</style>
