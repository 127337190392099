<template>
<div>
    <div class="block">
        <span class="demonstration">厅馆彩池</span>
        <el-divider></el-divider>
        <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="roomId" label="厅馆ID" width="180">
            </el-table-column>
            <el-table-column prop="goldHorse" label="黄金马彩池" width="180">
            </el-table-column>
            <el-table-column prop="doubly" label="倍率赛彩池" width="180">
            </el-table-column>
            <el-table-column prop="diamondHorse" label="钻石马彩池" width="180">
            </el-table-column>
        </el-table>
        <el-divider></el-divider>
        <span class="demonstration">厅馆库存</span>
        <el-divider></el-divider>
        <el-table :data="bankerData" style="width: 100%">
            <el-table-column prop="roomId" label="厅馆ID" width="180">
            </el-table-column>
            <el-table-column prop="value" label="库存值" width="180">
            </el-table-column>
        </el-table>
        <el-divider></el-divider>
        <span class="demonstration">查看场次统计</span>
        <el-divider></el-divider>
        统计开始时间
        <el-date-picker v-model="startDate" type="datetime" placeholder="选择统计开始时间" align="right">
        </el-date-picker>
        统计结束时间
        <el-date-picker v-model="endDate" type="datetime" placeholder="选择统计结束时间" align="right">
        </el-date-picker>
        <el-button @click="subMit" style="margin-left: 15px" type="primary">查询</el-button>
        <el-divider></el-divider>
    </div>
    <div class="conentBox">
        <el-empty v-show="isEmpty" :description="emptyTip"></el-empty>
        <div class="showBox" v-show="!isEmpty">
            <div>
                <div class="showItem">黄金马理论次数:{{data.goldHorseTheory}}</div>
                <div class="showItem">黄金马实际次数:{{data.goldHorseActual}}</div>
            </div>
            <div>
                <div class="showItem">倍率赛理论次数:{{data.doublyTheory}}</div>
                <div class="showItem">倍率赛实际次数:{{data.doublyActual}}</div>
            </div>
            <div>
                <div class="showItem">钻石马理论次数:{{data.diamondHorseTheory}}</div>
                <div class="showItem">钻石马实际次数:{{data.diamondHorseActual}}</div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
export default {
    data() {
        return {
            emptyTip: "无",
            startDate: new Date(0),
            endDate: new Date(),
            tableData: [],
            bankerData: [],
            isEmpty: true,
            data: {
                goldHorseTheory: 0, //黄金马理论开出次数
                doublyTheory: 0, // 倍率赛林论开出次数
                diamondHorseTheory: 0, //钻石马理论开出次数
                goldHorseActual: 0, //黄金马实际开出次数
                doublyActual: 0, //倍率赛实际次数
                diamondHorseActual: 0 //钻石马实际次数
            }
        }
    },
    async created() {
        await this.setJpData();
        await this.getBanker();
    },
    methods: {
        async setJpData() {
            this.$req.url = this.reqConf.getHorseRacePool;
            this.$req.type = "post";
            const param = {
                token: window.sessionStorage.getItem("token")
            }
            this.$req.param = param;
            const res = await this.$req.data;
            if (res.code == 0) {
                this.tableData = res.data;
            }
        },
        async getBanker() {
            this.$req.url = this.reqConf.getHorseRaceBanker;
            this.$req.type = "post";
            const param = {
                token: window.sessionStorage.getItem("token")
            }
            this.$req.param = param;
            const res = await this.$req.data;
            if (res.code == 0) {
                this.bankerData = res.data;
            }
        },
        async subMit() {
            this.$req.url = this.reqConf.getHorseRaceStatistics;
            this.$req.type = "post";
            const startTime = Math.floor((+this.startDate) / 1000);
            const endTime = Math.floor((+this.endDate) / 1000);
            const param = {
                token: window.sessionStorage.getItem("token"),
                startTime: startTime,
                endTime: endTime,
            }
            this.$req.param = param;
            this.emptyTip = '加载中...';
            try {
                const res = await this.$req.data;
                if (res.code == 0) {
                    this.data = res.data;
                    this.isEmpty = false;
                } else {
                    this.isEmpty = true;
                    this.emptyTip = res.msg;
                }
            } catch (e) {
                this.isEmpty = true;
                this.emptyTip = e.msg;
                console.log(e)
            }
        },
    }
}
</script>

<style>
.userInput {
    width: 280px !important;
}

.showBox>div {
    display: flex;
}

.showItem {
    margin-bottom: 10px;
    width: 280px;
    height: 100px;
    border-radius: 4px;
    background-color: #409EFF;
    text-align: center;
    color: #fff;
    padding: 40px 20px;
    box-sizing: border-box;
    margin-right: 20px;
}
</style>
