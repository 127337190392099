<template>
<div>
    <pubTable :table="table"></pubTable>
    <div class="block">
        <el-pagination @size-change="changeSize" @current-change="changePage" :current-page="page" :page-sizes="[10, 20, 30, 50 ,100]" :page-size="size" layout="total, sizes, prev, pager, next, jumper" :total="count">
        </el-pagination>
    </div>
</div>
</template>

<script>
export default {
    components: {
        pubTable: () => import("@/components/public/table"),
    },
    async created() {
        await this.request();
    },
    methods: {
        async request() {
            this.$req.url = this.reqConf.stat;
            this.$req.type = 'post';
            this.$req.param = {
                token: window.sessionStorage.getItem("token"),
                page: this.page,
                size: this.size,
                gameId: this.gameId,
                isAllRoom: 0,
                isTreasure: 1,
                isJp: 1
            };
            const res = await this.$req.bind(this);
            this.table.fieldData = this.setField();
            this.table.tableData = this.setRoomName(res.data.list);
        },
        setField() {
            return [{
                    "field": "dateStr",
                    "label": "统计日期"
                },
                {
                    "field": "gameId",
                    "label": "游戏名称"
                },
                {
                    "field": "roomId",
                    "label": "游戏房间"
                },
                {
                    "field": "1",
                    "label": "单牌"
                }, {
                    "field": "2",
                    "label": "对子"
                }, {
                    "field": "3",
                    "label": "同花"
                }, {
                    "field": "4",
                    "label": "顺子"
                }, {
                    "field": "5",
                    "label": "三条"
                }, {
                    "field": "6",
                    "label": "同花顺"
                }, {
                    "field": "7",
                    "label": "同花大顺"
                }, {
                    "field": "8",
                    "label": "黑桃同花大顺"
                }
            ];
        },
        setRoomName(data) {
            return data.map(item => {
                let roomName = '';
                switch (item.roomId) {
                    case 10102:
                        roomName = '体验场';
                        break;
                    case 10202:
                        roomName = '初级场';
                        break;
                    case 10302:
                        roomName = '中级场';
                        break;
                    case 10402:
                        roomName = '高级场';
                        break;
                    case 10502:
                        roomName = '大师场';
                        break;
                }
                item.roomId = roomName;
                item.gameId = '三张扑克';
                item = {
                    ...item,
                    ...item.detail
                }
                console.log(item)
                return item;
            });
        },
        async changeSize(data) {
            this.size = data;
            await this.request();
        },
        async changePage(data) {
            this.page = data;
            await this.request();
        }
    },
    data() {
        return {
            gameId: 1,
            count: 100,
            page: 1,
            size: 20,
            table: {
                fieldData: [],
                tableData: []
            }
        }
    },
};
</script>

<style scoped>
.block {
    margin-top: 25px;
}
</style>
