<template>
<div>
    <div class="block">
        <span class="demonstration">查看机器人库存</span>
        <el-divider></el-divider>
        <el-cascader v-model="value" :options="options" @change="handleChange"></el-cascader>
        <el-button @click="subMit" style="margin-left: 15px" type="primary">查询</el-button>
        <el-divider></el-divider>
        <span class="inputText">玩家库存:</span>
        <div class="inputClass">
            <el-input-number v-model="input.amount" :disabled="true" :precision="2" :step="0.1"></el-input-number>
        </div>
        <el-divider></el-divider>
        <span class="inputText">机器人库存:</span>
        <div class="inputClass">
            <el-input-number v-model="input.robotAmount" :disabled="true" :precision="2" :step="0.1"></el-input-number>
        </div>
        <el-divider></el-divider>
        <span class="inputText">玩家JP库存:</span>
        <div class="inputClass">
            <el-input-number v-model="input.jackpotAmount.player" :disabled="true" :precision="2" :step="0.1"></el-input-number>
        </div>
        <el-divider></el-divider>
        <span class="inputText">机器人JP库存:</span>
        <div class="inputClass">
            <el-input-number v-model="input.jackpotAmount.robot" :disabled="true" :precision="2" :step="0.1"></el-input-number>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            input: {
                amount: 0,
                robotAmount: 0,
                jackpotAmount: {
                    player: 0,
                    robot: 0,
                },
            },
            value: [],
            options: [{
                    value: "5",
                    label: "聚宝盆",
                    children: [{
                            value: "1",
                            label: "体验馆",
                        },
                        {
                            value: "2",
                            label: "初级馆",
                        },
                        {
                            value: "3",
                            label: "中级馆",
                        },
                        {
                            value: "4",
                            label: "高级馆",
                        },
                    ],
                },
                {
                    value: "6",
                    label: "五色宝石",
                    children: [{
                            value: "12",
                            label: "初级馆",
                        },
                        {
                            value: "13",
                            label: "中级馆",
                        },
                        {
                            value: "14",
                            label: "高级馆",
                        },
                    ],
                }, {
                    value: "11",
                    label: "推筒子",
                    children: [{
                            value: "1",
                            label: "体验馆",
                        },
                        {
                            value: "2",
                            label: "初级馆",
                        },
                        {
                            value: "3",
                            label: "中级馆",
                        }, {
                            value: "4",
                            label: "高级馆",
                        }
                    ],
                },
            ],
        };
    },
	mounted(){
		//处理ace平台厅馆id需要增加10
		if(this.moneyConfig.siteName == "aceonline"){
			this.options.forEach(item=>{
				if(item.value == "11"){
					item = item.children.map(item=>{
						item.value = String(Number(item.value) + 10);
					})
				}
			})
		}
	},
    methods: {
        handleChange(value) {
            if (value.length < 2) {
                return this.error("请选择要查看的游戏与厅馆");
            }
            console.log(value);
        },
        async subMit() {
            if (this.value.length < 2) {
                return this.error("请选择要查看的游戏与厅馆");
            }
            this.$req.url = this.reqConf.getRobotStock;
            this.$req.type = "post";
            this.$req.param = {
                token: window.sessionStorage.getItem("token"),
                gameId: this.value[0],
                roomId: this.value[1],
            };
            const res = await this.$req.data;
            if (res.code == 0) {
                this.success("获取成功");
                this.input.amount = res.data.amount ?? 0;
                this.input.robotAmount = res.data.robotAmount ?? 0;
                this.input.jackpotAmount.player = res.data.jackpotAmount.player ?? 0;
                this.input.jackpotAmount.robot = res.data.jackpotAmount.robot ?? 0;
            } else {
                this.error(res.msg);
            }
        },
    },
};
</script>

<style>
.inputText {
    font-size: 14px;
    color: #333;
}

.inputClass {
    width: 220px;
    margin-top: 10px;
}

.el-input-number {
    width: 280px !important;
}
</style>
