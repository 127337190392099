<template>
<div>
    <div class="block">
        <span class="demonstration">三张扑克配置</span>
        <el-divider></el-divider>
        <el-table :data="threeCardData" style="width: 100%">
            <el-table-column prop="roomId" label="厅馆ID" width="180">
            </el-table-column>
            <el-table-column prop="rtpNum" label="RTP干预" width="180">
            </el-table-column>
            <el-table-column prop="changeRate" label="换牌干预" width="180">
            </el-table-column>
            <el-table-column prop="bigCardKillScoreSwitch" label="大牌干预开关" width="180">
            </el-table-column>
        </el-table>
        <el-divider></el-divider>
        <span class="demonstration">倍率21点配置</span>
        <el-divider></el-divider>
        <el-table :data="blackJackData" style="width: 100%">
            <el-table-column prop="roomId" label="厅馆ID" width="180">
            </el-table-column>
            <el-table-column prop="killScoreRate" label="庄换牌概率" width="180">
            </el-table-column>
        </el-table>
        <el-divider></el-divider>
    </div>

</div>
</template>

<script>
export default {
    data() {
        return {
            threeCardData: [],
            blackJackData: []
        }
    },
    async created() {
        await this.getThreeCardData();
        await this.getBalckJackData();
    },
    methods: {
        async getThreeCardData() {
            this.$req.url = this.reqConf.getThreeCardConfig;
            this.$req.type = "post";
            const param = {
                token: window.sessionStorage.getItem("token")
            }
            this.$req.param = param;
            const res = await this.$req.data;
            if (res.code == 0) {
                res.data.configArr = res.data.configArr.map(item => {
                    item.bigCardKillScoreSwitch = String(item.bigCardKillScoreSwitch);
                    return item;
                })
                this.threeCardData = res.data.configArr;
            }
        },
        async getBalckJackData() {
            this.$req.url = this.reqConf.getBlackJackConfig;
            this.$req.type = "post";
            const param = {
                token: window.sessionStorage.getItem("token")
            }
            this.$req.param = param;
            const res = await this.$req.data;
            if (res.code == 0) {
                this.blackJackData = res.data.configArr;
            }
        }
    }
}
</script>

<style>
.userInput {
    width: 280px !important;
}

.showBox>div {
    display: flex;
}

.showItem {
    margin-bottom: 10px;
    width: 280px;
    height: 100px;
    border-radius: 4px;
    background-color: #409EFF;
    text-align: center;
    color: #fff;
    padding: 40px 20px;
    box-sizing: border-box;
    margin-right: 20px;
}
</style>
