<template>
<div>
    <pubTable :table="table"></pubTable>
    <div class="block">
        <el-pagination @size-change="changeSize" @current-change="changePage" :current-page="page" :page-sizes="[10, 20, 30, 50 ,100]" :page-size="size" layout="total, sizes, prev, pager, next, jumper" :total="count">
        </el-pagination>
    </div>
</div>
</template>

<script>
export default {
    components: {
        pubTable: () => import("@/components/public/table"),
    },
    async created() {
        await this.request();
    },
    methods: {
        async request() {
            this.$req.url = this.reqConf.stat;
            this.$req.type = 'post';
            this.$req.param = {
                token: window.sessionStorage.getItem("token"),
                page: this.page,
                size: this.size,
                gameId: this.gameId,
                isAllRoom: 1
            };
            const res = await this.$req.bind(this);
            this.table.fieldData = res.data.field;
            this.table.fieldData.push({
                field: "tw",
                label: "台币"
            });
            this.table.fieldData.push({
                field: "rmb",
                label: "和人民币"
            });
            this.table.tableData = this.setRoomName(res.data.list);
        },
        setRoomName(data) {
            return data.map(item => {
                item.roomId = '所有场馆';
                item.gameId = '五色宝石';
                item['tw'] = Math.round((item.betMoney - item.payment) / this.moneyConfig.moneyRate);
                item['rmb'] = Math.round(item['tw'] * this.moneyConfig.exchangeRate);
                return item;
            });
        },
        async changeSize(data) {
            this.size = data;
            await this.request();
        },
        async changePage(data) {
            this.page = data;
            await this.request();
        }
    },
    data() {
        return {
            gameId: 6,
            count: 100,
            page: 1,
            size: 20,
            table: {
                fieldData: [],
                tableData: []
            }
        }
    },
};
</script>

<style scoped>
.block {
    margin-top: 25px;
}
</style>
