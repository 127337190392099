import vue from 'vue';
let Config = {
    'login': "/admin/login",//登录接口地址
    'reset': "/admin/reSetPassword",//重置密码接口
    'stat': "/admin/data/getGameStat",//游戏统计
    'getRobotStock': "/pinus/getRobotStock",//游戏统计
    'pushGlobalMessage': "/pinus/pushGlobalMessage",//推送全局消息
    "setMaintain": "/pinus/setMaintain",//设置维护状态
    "getMainStatus": "/pinus/getMainStatus",//获取维护状态
    "getPlayerStat": "/admin/data/getPlayerStat",//获取玩家统计
    "getPlayerBetList": "/admin/data/getPlayerBetList",//获取玩家下注记录
    "getHorseRaceStatistics": "/horseRaceApi/getStatistics",//获取赛马统计
    "getHorseRacePool": "/horseRaceApi/getPool",//获取赛马统计
    "getHorseRaceBanker": "/horseRaceApi/getBanker",//查看赛马库存
    "getThreeCardConfig": "/killConfig/getThreeCardConfig",//查看三张扑克干预
    "getBlackJackConfig": "/killConfig/getBlackJackConfig",//查看21点干预
    "setThreeCardKillScore": "/killConfig/setThreeCardKillScore",//设置三张扑克干预
    "setBalckJackKillScore": "/killConfig/setBalckJackKillScore",//设置21点干预
    "getRoomStatField": "/roomCard/getStatField",//获取房卡统计字段
    "getRoomStatDetailField": "/roomCard/getStatDetailField",//获取房卡详情字段
    "getRoomStatData": "/roomCard/getStatData",//获取房卡统计数据
    "getRoomStatDetailData": "/roomCard/getStatDetailData",//获取房卡详情数据
};
vue.prototype.reqConf = Config;
vue.prototype.moneyConfig = {
    moneyRate: 1000,
    exchangeRate: 0.2335,
    siteName: 'localhost'
}

export default Config;