<template>
<div>
    <div class="block">
        <span class="demonstration">查看玩家统计</span>
        <el-divider></el-divider>
        <el-select v-model="gameId" placeholder="请选择">
            <el-option v-for="item in gameList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
        </el-select>
        <el-input class="userInput" v-model="userId" placeholder="请输入平台ID"></el-input>
        统计开始时间
        <el-date-picker v-model="startDate" type="datetime" placeholder="选择统计开始时间" align="right">
        </el-date-picker>
        统计结束时间
        <el-date-picker v-model="endDate" type="datetime" placeholder="选择统计结束时间" align="right">
        </el-date-picker>
        <el-button @click="subMit" style="margin-left: 15px" type="primary">查询</el-button>
        <el-divider></el-divider>
    </div>
    <div class="conentBox">
        <el-empty v-show="isEmpty" :description="emptyTip"></el-empty>
        <div class="showBox" v-show="!isEmpty">
            <div>
                <div class="showItem">昵称:{{detail.nickName}}</div>
                <div class="showItem">累计在线时间:{{detail.onlineTime}}小时</div>
            </div>
            <div>
                <div class="showItem">下注:{{detail.betMoney}}</div>
                <div class="showItem">派彩:{{detail.payment}}</div>

            </div>
            <div>
                <div class="showItem">下注次数:{{detail.betNum}}</div>
                <div class="showItem">玩家RTP:{{detail.rtp}}%</div>
            </div>
            <div>
                <div class="showItem">输赢:{{detail.profit}}</div>
                <div class="showItem">开桌游戏对局数:{{detail.tableRoundNum}}</div>
            </div>
            <div>
                <div class="showItem">开桌消耗:{{detail.tableCreateMoney}}</div>
                <div class="showItem">道具消耗:{{detail.tableEmoMoney}}</div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
export default {
    data() {
        return {
            gameList: [{
                value: 0,
                label: '所有游戏'
            }, {
                value: 1,
                label: '三张扑克'
            }, {
                value: 2,
                label: '倍率21点'
            }, {
                value: 4,
                label: '王牌21点'
            }, {
                value: 5,
                label: '聚宝盆'
            }, {
                value: 6,
                label: '五色宝石'
            }, {
                value: 10,
                label: '赛马'
            }, {
                value: 13,
                label: '十三水'
            }, {
                value: 15,
                label: '麻将'
            }],
            gameId: 0,
            emptyTip: "无",
            userId: "",
            startDate: new Date().setHours(0, 0, 0, 0),
            endDate: new Date(),
            isEmpty: true,
            detail: {
                nickName: "123",
                onlineTime: 12,
                betMoney: 1,
                payment: 1,
                betNum: 1,
                rtp: 1,
                tableRoundNum:0,
                tableCreateMoney:0,
                tableEmoMoney:0
            }
        }
    },
    methods: {
        async subMit() {
            this.$req.url = this.reqConf.getPlayerStat;
            this.$req.type = "post";
            const startTime = Math.floor((+this.startDate) / 1000);
            const endTime = Math.floor((+this.endDate) / 1000);
            const param = {
                token: window.sessionStorage.getItem("token"),
                userId: this.userId,
                gameId: this.gameId,
                startTime: startTime,
                endTime: endTime,
            }
            this.$req.param = param;
            this.emptyTip = '加载中...';
            try {
                const res = await this.$req.data;
                if (res.code == 0) {
                    this.isEmpty = false;
                    this.detail = res.data;
                    this.detail.rtp = (Math.floor((Number(this.detail.payment) / Number(this.detail.betMoney)) * 10000)) / 100;
                    this.detail.profit = (Number(this.detail.betMoney) - Number(this.detail.payment)).toLocaleString('en-US')

                    this.detail.payment = this.detail.payment.toLocaleString('en-US')
                    this.detail.betMoney = this.detail.betMoney.toLocaleString('en-US')

                } else {
                    this.isEmpty = true;
                    this.emptyTip = res.msg;
                }
            } catch (e) {
                this.isEmpty = true;
                this.emptyTip = e.msg;
                console.log(e)
            }
        },
    }
}
</script>

<style>
.userInput {
    width: 280px !important;
}

.showBox>div {
    display: flex;
}

.showItem {
    margin-bottom: 10px;
    width: 280px;
    height: 100px;
    border-radius: 4px;
    background-color: #409EFF;
    text-align: center;
    color: #fff;
    padding: 40px 20px;
    box-sizing: border-box;
    margin-right: 20px;
}
</style>
