import { render, staticRenderFns } from "./treasure.vue?vue&type=template&id=569ca3f4&scoped=true&"
import script from "./treasure.vue?vue&type=script&lang=js&"
export * from "./treasure.vue?vue&type=script&lang=js&"
import style0 from "./treasure.vue?vue&type=style&index=0&id=569ca3f4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "569ca3f4",
  null
  
)

export default component.exports