import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../components/login.vue';
import home from '../components/home.vue';
import resetPassword from '../components/page/admin/resetPassword.vue';
import threeCard from '../components/page/stat/threeCard/threeCard.vue';
import threeCardRoom from '../components/page/stat/threeCard/threeCardRoom.vue';
import threeCardType from '../components/page/stat/threeCard/threeCardType.vue';
import blackJackRoom from '../components/page/stat/blackJack/blackJackRoom.vue';
import blackJack from '../components/page/stat/blackJack/blackJack.vue';
import classicBlackJackRoom from '../components/page/stat/classicBlackJack/blackJackRoom.vue';
import classicBlackJack from '../components/page/stat/classicBlackJack/blackJack.vue';
import treasure from '../components/page/stat/treasure/treasure.vue';
import treasureRoom from '../components/page/stat/treasure/treasureRoom.vue';
import robotStock from '../components/page/game/robotStock.vue';
import pushMessage from '../components/page/game/pushMessage.vue';
import killScore from '../components/page/game/killScore.vue';
import setKillScore from '../components/page/game/setKillScore.vue';
import gemstone from '../components/page/stat/gemstone/gemstone.vue';
import gemstoneRoom from '../components/page/stat/gemstone/gemstoneRoom.vue';
import horseRace from '../components/page/stat/horseRace/horseRace.vue';
import horseRaceRoom from '../components/page/stat/horseRace/horseRaceRoom.vue';
import playerStat from '../components/page/player/playerStat.vue';
import betList from '../components/page/player/betList.vue';
import horseRaceStat from '../components/page/stat/horseRace/horseRaceStat.vue';
import sanShui from '../components/page/stat/sanshui/sanShui.vue';
import mahjong from '../components/page/stat/mahjong/mahjong.vue';
import roomCard from '../components/page/player/roomCard.vue';

Vue.use(VueRouter);

const routes = [
	{ path: '/', redirect: '/login' },
	{
		path: '/login',
		name: 'login',
		component: login
	},
	{
		path: '/home',
		name: 'home',
		component: home,
		redirect: '/home/admin/resetPassword',
		children: [
			{
				path: '/home/admin/resetPassword',
				component: resetPassword
			},
			{
				path: '/home/player/stat',
				component: playerStat
			}, {
				path: '/home/player/betList',
				component: betList
			}, {
				path: '/home/roomCard/detail',
				component: roomCard
			},
			{
				path: '/home/stat/threeCard',
				component: threeCard
			}, {
				path: '/home/stat/threeCardRoom',
				component: threeCardRoom
			}, {
				path: '/home/stat/threeCardType',
				component: threeCardType
			}, {
				path: '/home/stat/blackJackRoom',
				component: blackJackRoom
			}, {
				path: '/home/stat/blackJack',
				component: blackJack
			}, {
				path: '/home/stat/classicBlackJack',
				component: classicBlackJack
			}, {
				path: '/home/stat/classicBlackJackRoom',
				component: classicBlackJackRoom
			}, {
				path: '/home/stat/treasure',
				component: treasure
			}, {
				path: '/home/stat/treasureRoom',
				component: treasureRoom
			}, {
				path: '/home/stat/gemstone',
				component: gemstone
			}, {
				path: '/home/stat/gemstoneRoom',
				component: gemstoneRoom
			}, {
				path: '/home/stat/horseRace',
				component: horseRace
			}, {
				path: '/home/stat/horseRaceRoom',
				component: horseRaceRoom
			}, {
				path: '/home/stat/horseRaceStat',
				component: horseRaceStat
			}, {
				path: '/home/game/robotStock',
				component: robotStock
			}, {
				path: '/home/game/pushMessage',
				component: pushMessage
			}, {
				path: '/home/game/killScore',
				component: killScore
			}, {
				path: '/home/game/setKillScore',
				component: setKillScore
			},{
				path: "/home/stat/sanshui",
				component: sanShui
			},{
				path: "/home/stat/mahjong",
				component: mahjong
			}
		]
	}
]

const router = new VueRouter({
	routes
})

router.beforeEach((to, from, next) => {
	if (to.path === '/login') return next();
	// 获取token
	const tokenStr = window.sessionStorage.getItem('token')
	if (!tokenStr) {
		return next('/login')
	}
	next()
})

export default router
