<template>
<div>
    <h2 slot="title" class="form_title">
        {{getTitle}}
    </h2>
    <form-core :form="form" @action-click="formClick">
        <slot></slot>
    </form-core>
</div>
</template>

<script>
export default {
    /**
     * 此事件如果使用组件,必须有监听,否则无法给父组件传递状态,导致下一次无法开启
     * @event close 窗口关闭
     * 
     * @event action-click 提交按钮被点击
     * @param data 表单JSON对象
     * 
     */
    props: [
        //表单结构与数据
        'form'
    ],
    components: {
        'form-core': () => import('@/components/public/from_core')
    },
    computed: {
        getTitle() {
            let def = "表单";
            if (typeof (this.form) == 'undefined') {
                return def;
            }
            if (typeof (this.form.title) == 'undefined') {
                return def;
            }
            return this.form.title;
        }
    },
    methods: {
        formClick(data) {
            this.$emit('action-click', data);
        }
    },
    data() {
        return {
            windowShow: true,
        };
    },
    created() {

    }
};
</script>

<style scoped>
.form_title {
    border-left: 5px solid #409EFF;
    text-indent: 15px;
    font-weight: 500;
    font-size: 22px;
    height: 36px;
    line-height: 36px;
    margin-bottom: 30px;
}
</style>
