<template>
<div>
    <el-form class="pubform">
        <template v-for="(item,index) in getForm" :index="index">
            <el-form-item :label="item.title+':'" v-show="item.type!='hidden'" :key="index">
                <template v-if="item.type=='hidden'">
                    <el-input class="xt-el-hidden" type="hidden" v-model="item.value"></el-input>
                </template>
                <template v-else-if="item.type=='text'">
                    <el-input class="xt-el-text" v-model="item.value" :placeholder="item.args.placeholder" :style="'width:'+item.args.width+'px'"></el-input>
                </template>
                <template v-else-if="item.type=='password'">
                    <el-input class="xt-el-password" v-model="item.value" type="password" :placeholder="item.args.placeholder" :style="'width:'+item.args.width+'px'"></el-input>
                </template>
                <template v-else-if="item.type=='text_show'">
                    <span style="font-size:14px;color:#555;margin-left:10px;">{{item.value}}</span>
                </template>
                <template v-else-if="item.type=='number'">
                    <el-input-number class="xt-el-number" v-model="item.value" :placeholder="item.args.placeholder" :style="'width:'+item.args.width+'px'"></el-input-number>
                </template>
                <template v-else-if="item.type=='textarea'">
                    <el-input class="xt-el-textarea" v-model="item.value" type="textarea" :placeholder="item.args.placeholder" :style="'width:'+item.args.width+'px;'"></el-input>
                </template>
                <template v-else-if="item.type=='toggle'">
                    <el-switch class="xt-el-toggle" v-model="item.value"></el-switch>
                </template>
            </el-form-item>
        </template>
        <slot></slot>
        <el-form-item>
            <el-button type="primary" @click="getFormParams">{{getActionButtonName}}</el-button>
        </el-form-item>
    </el-form>
</div>
</template>

<script>
export default {
    /**
     * 
     * @event action-click 提交按钮被点击
     * @param data 表单JSON对象
     * 
     */
    props: [
        //表单结构与数据
        'form'
    ],
    computed: {
        getActionButtonName() {
            let def = "提交";
            if (typeof (this.form) == 'undefined') {
                return def;
            }
            if (typeof (this.form.actionName) == 'undefined') {
                return def;
            }
            return this.form.actionName;
        },
        getForm() {
            if (typeof (this.form) == 'undefined') {
                return [];
            }
            if (typeof (this.form.list) == 'undefined') {
                return [];
            }
            this.form.list.forEach(item => {
                switch (item.type) {
                    case 'toggle':
                        item.value = item.value == 0 ? false : true;
                        break;
                    case 'date':
                        break;
                }
            });
            return this.form.list;
        }
    },
    methods: {
        getFormParams() {
            let requestInfo = {};
            this.getForm.forEach(item => {
                //toggle返回的boolean
                let value = item.value;

                switch (item.type) {
                    case 'toggle':
                        value = item.value === true ? 1 : 0;
                        break;
                    case 'date':
                        if (item.value instanceof Date) {
                            value = item.value.format('yyyy-MM-dd');
                        }
                        break;
                }
                requestInfo[item.field] = value;
            });
            this.$emit('action-click', requestInfo);
        }
    },
    data() {
        return {};
    }
}
</script>

<style scoped>

</style>
