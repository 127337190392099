<template>
<el-container class="container">
    <el-header class="header">
        <el-menu default-active="0" class="el-menu" mode="horizontal" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
            <router-link to="/home">
                <el-menu-item class="headerName">游戏管理后台</el-menu-item>
            </router-link>
            <router-link to="/home/admin/resetPassword">
                <el-menu-item>修改密码</el-menu-item>
            </router-link>
            <el-menu-item @click="logout()">退出登录</el-menu-item>
        </el-menu>
    </el-header>
    <el-container class="body">
        <el-aside class="aside">
            <el-menu unique-opened default-active="2" class="el-menu-vertical-demo" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
                <div v-for="(item, index) in menuList" :key="index">
                    <el-submenu :index="String(index)" v-if="item.type == 0">
                        <template #title>
                            <span>{{ item.name }}</span>
                        </template>
                        <el-menu-item @click="toPage(menuItem.router)" v-for="(menuItem, menuIndex) in item.list" :key="menuIndex">{{ menuItem.name }}</el-menu-item>
                    </el-submenu>
                    <el-menu-item @click="toPage(item.router)" v-if="item.type == 1">
                        <template #title>{{ item.name }}</template>
                    </el-menu-item>
                </div>
            </el-menu>
        </el-aside>
        <el-main class="main">
            <router-view />
        </el-main>
    </el-container>
</el-container>
</template>

<script>
export default {
    data() {
        return {
            menuList: [{
                    type: 0,
                    name: "维护",
                    router: "/",
                    list: [{
                            name: "消息推送",
                            router: "/home/game/pushMessage",
                        },
                        {
                            name: "游戏库存查看",
                            router: "/home/game/robotStock",
                        },
                        {
                            name: "游戏干预查看",
                            router: "/home/game/killScore",
                        }, {
                            name: "游戏干预设置",
                            router: "/home/game/setKillScore",
                        },
                    ],
                },
                {
                    type: 0,
                    name: "统计与明细",
                    router: "/",
                    list: [{
                            name: "玩家统计",
                            router: "/home/player/stat",
                        },
                        {
                            name: "玩家注单明细",
                            router: "/home/player/betList",
                        },
                        {
                            name: "房卡局明细",
                            router: "/home/roomCard/detail"
                        }
                    ],
                },
                {
                    type: 0,
                    name: "三张扑克",
                    router: "/",
                    list: [{
                            name: "游戏统计",
                            router: "/home/stat/threeCard",
                        },
                        {
                            name: "场馆统计",
                            router: "/home/stat/threeCardRoom",
                        },
                        {
                            name: "牌型统计",
                            router: "/home/stat/threeCardType",
                        },
                    ],
                },
                {
                    type: 0,
                    name: "倍率21点",
                    router: "/",
                    list: [{
                            name: "游戏统计",
                            router: "/home/stat/blackJack",
                        },
                        {
                            name: "场馆统计",
                            router: "/home/stat/blackJackRoom",
                        },
                    ],
                },
                {
                    type: 0,
                    name: "王牌21点",
                    router: "/",
                    list: [{
                            name: "游戏统计",
                            router: "/home/stat/classicBlackJack",
                        },
                        {
                            name: "场馆统计",
                            router: "/home/stat/classicBlackJackRoom",
                        },
                    ],
                },
                {
                    type: 0,
                    name: "聚宝盆",
                    router: "/",
                    list: [{
                            name: "游戏统计",
                            router: "/home/stat/treasure",
                        },
                        {
                            name: "场馆统计",
                            router: "/home/stat/treasureRoom",
                        },
                    ],
                }, {
                    type: 0,
                    name: "五色宝石",
                    router: "/",
                    list: [{
                            name: "游戏统计",
                            router: "/home/stat/gemstone",
                        },
                        {
                            name: "场馆统计",
                            router: "/home/stat/gemstoneRoom",
                        },
                    ],
                }, {
                    type: 0,
                    name: "赛马",
                    router: "/",
                    list: [{
                            name: "游戏统计",
                            router: "/home/stat/horseRace",
                        },
                        {
                            name: "场馆统计",
                            router: "/home/stat/horseRaceRoom",
                        }, {
                            name: "其他统计",
                            router: "/home/stat/horseRaceStat",
                        },
                    ],
                }, {
                    type: 0,
                    name: "十三水",
                    router: "/",
                    list: [{
                        name: "游戏统计",
                        router: "/home/stat/sanshui",
                    }],
                }, {
                    type: 0,
                    name: "麻将",
                    router: "/",
                    list: [{
                        name: "游戏统计",
                        router: "/home/stat/mahjong",
                    }],
                }
            ],
        };
    },
    created() {},
    methods: {
        toPage: function (path) {
            this.$router.push(path);
        },
        logout: function () {
            this.$confirm("确认要退出登录吗?", "登出", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                .then(() => {
                    this.$message({
                        type: "success",
                        message: "退出成功!",
                    });
                    window.sessionStorage.removeItem("token");
                    this.$router.push("/login");
                })
                .catch(() => {});
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    background: #eee;
    height: 100%;
    flex-wrap: wrap;

    .header {
        .el-menu {
            display: flex;
            justify-content: flex-end;
            position: relative;

            .headerName {
                width: 300px;
                position: absolute;
                left: 0;
                top: 0;
            }
        }

        padding: 0;
        background: #ddd;
        width: 100%;
        height: 60px;
    }

    .body {
        width: 100%;
        height: calc(100% - 60px);

        .aside {
            background: #7b8590;
            height: 100%;
            width: 250px;
        }

        .main {
            width: calc(100% - 90px);
            height: 100%;
            background: #fff;
        }
    }
}
</style>
