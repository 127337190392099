<template>
<div id="box">
    <router-view></router-view>
</div>
</template>

<script>
export default {
    data() {
        return {};
    },
    created() {
		document.querySelector("title").innerHTML = this.moneyConfig.siteName;
    }
};
</script>

<style lang="scss">
* {
    padding: 0;
    margin: 0;
}

html,
body {
    height: 100%;
}

#box {
    height: 100%;
}
</style>
