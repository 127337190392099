<template>
<div>
    <el-table :data="tableData" border :show-summary="summary" :summary-method="getSummaries" sum-text="本页合计" style="width: 100%">
        <div v-for="(item,index) in getTableField" :key="index">
            <el-table-column v-if="!item.button" :prop="item.field" :label="item.label" :width="item.width">
                <div slot-scope="scope">
                    <a :class="tableClassName(item,scope.row)">{{scope.row[item.field]}}</a>
                </div>
            </el-table-column>

            <el-table-column v-if="!!item.button" :prop="item.field" :label="item.label" :width="item.width">
                <div slot-scope="scope">
                    <el-button @click="item.cb(scope)" type="text" size="small">{{item.label}}</el-button>
                </div>

            </el-table-column>
        </div>

    </el-table>
</div>
</template>

<script>
export default {
    props: [
        //表单结构与数据
        'table'
    ],
    created() {
        this.fieldData = this.table.fieldData ?? [];
        this.tableData = this.table.tableData ?? [];
        this.summary = this.table.summary ?? true;
    },
    watch: {
        'table': {
            handler(newVal) {
                this.fieldData = newVal.fieldData ?? [];
                if (newVal.tableData) {
                    newVal.tableData.forEach(iteam => {
                        if (!iteam.profit) {
                            iteam.paymentBack = iteam.payment;
                            iteam.betMoneyBack = iteam.betMoney;
                            const profit = Number(iteam.betMoneyBack) - Number(iteam.paymentBack);
                            iteam.profitBack = profit;
                            iteam.profit = profit.toLocaleString('en-US')
                        }
                        if(iteam.betMoney){
                            iteam.betMoney = iteam.betMoney.toLocaleString('en-US')
                        }
                        if(iteam.payment){
                            iteam.payment = iteam.payment.toLocaleString('en-US')
                        }
                        if(iteam.platformTax){
                            iteam.platformTax = iteam.platformTax.toLocaleString('en-US')
                        }
                        if (iteam.rmb) {
                            iteam.rmb = iteam.rmb.toLocaleString('en-US')
                        }
                        if (iteam.tw) {
                            iteam.tw = iteam.tw.toLocaleString('en-US')
                        }

                    });
                }

                this.tableData = newVal.tableData ?? [];
            },
            deep: true
        }
    },
    computed: {
        getTableField() {
            return this.fieldData.map(item => {
                item.width = item.width ?? 0;
                return item;
            });
        }
    },
    data() {
        return {
            summary: true,
            fieldData: [],
            tableData: []
        }
    },
    methods: {
        tableClassName(item, row) {
            if (item.field == 'profit' && row.profitBack < 0) {
                return 'red_color'
            }
            return ''
        },
        getSummaries(param) {
            const {
                columns,
                data
            } = param;
            const attr = ['payment', 'betMoney', 'tw', 'rmb', 'platformTax', 'poolMoney', 'treasurePayment', 'treasureMoney', 'profit']
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '本页合计行';
                    return;
                }
                if (!attr.includes(column.property)) {
                    sums[index] = '';
                    return;
                }
                const values = data.map(item => Number(`${item[column.property]}`.split(',').join('')));
                sums[index] = values.reduce((prev, curr) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr;
                    } else {
                        return prev;
                    }
                }, 0);
                sums[index] = Number(sums[index].toFixed(2)).toLocaleString('en-US')
            });
            return sums;
        }
    }
}
</script>

<style>
.el-table .red_color {
    color: brown;
}
</style>
