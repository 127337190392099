<template>
<div>
    <div class="block">
        <span class="demonstration">推送全局消息</span>
        <el-divider></el-divider>
        <span class="selectTitle">游戏:</span>
        <el-select v-model="gameId" placeholder="请选择对象">
            <el-option v-for="item in gameList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
        </el-select>
        <span class="selectTitle">内容:</span>
        <el-cascader v-model="value" :options="options" @change="handleChange"></el-cascader>
        <el-button @click="subMit" style="margin-left: 15px" type="primary">提交</el-button>
        <el-divider></el-divider>
        <span class="demonstration">服务器维护</span>
        <el-divider></el-divider>
        <el-alert title="预估维护时间(分钟),仅设置维护状态设置为【即将维护】与【正式维护】状态时生效,单位为分钟,到期后服务会恢复到正常开启状态." type="warning">
        </el-alert>
        <el-alert title="维护时间设置为0表示不限时,维护完成后需手动设置为开启状态" type="warning">
        </el-alert>
        <span class="selectTitle">当前维护状态:</span>
        <el-select v-model="status" placeholder="请选择对象">
            <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
        </el-select>
        <div class="maintain">
            <p class="selectTitle">预估维护时间:</p>
            <!-- <el-input-number v-model="num" @change="handleChange" :min="1" :max="10" label="请输入维护所需时间(分钟)"></el-input-number> -->
            <el-input v-model="maintainTime" placeholder="请输入维护所需时间(分钟)"></el-input>
        </div>
        <el-button @click="subMaintain" style="margin-left: 15px" type="primary">提交</el-button>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            value: [],
            gameId: "0",
            gameList: [{
                    value: "0",
                    label: "全服务器",
                },
                {
                    value: "1",
                    label: "三张扑克",
                }, {
                    value: "2",
                    label: "倍率21点",
                }, {
                    value: "4",
                    label: "王牌21点",
                }, {
                    value: "5",
                    label: "聚宝盆",
                }, {
                    value: "6",
                    label: "五色宝石",
                }, {
                    value: "9",
                    label: "德州扑克",
                }, {
                    value: "10",
                    label: "赛马",
                }, {
                    value: "11",
                    label: "推筒子",
                }, {
                    value: "12",
                    label: "六花扑克",
                }, {
                    value: "13",
                    label: "十三水",
                },
            ],
            options: [{
                    value: "1",
                    label: "通知",
                    children: [{
                            value: "3",
                            label: "即将维护通知",
                        },
                        {
                            value: "4",
                            label: "紧急维护通知",
                        }
                    ],
                },
                {
                    value: "2",
                    label: "操作",
                    children: [{
                            value: "5",
                            label: "强制退回平台大厅",
                        },
                        {
                            value: "6",
                            label: "弹出维护面板",
                        }
                    ],
                }
            ],
            maintainTime: 120,
            status: "0",
            statusList: [{
                    value: "0",
                    label: "正常开启 [正常运行]",
                },
                {
                    value: "1",
                    label: "即将维护 [玩家受限]",
                }, {
                    value: "2",
                    label: "正式维护 [禁止登录]",
                }
            ],
        };
    },
    methods: {
        handleChange(value) {
            if (value.length < 2) {
                return this.error("请选择要推送的消息");
            }
            console.log(value);
        },
        async subMit() {
            if (this.value.length < 2) {
                return this.error("请选择要推送的消息");
            }
            this.$req.url = this.reqConf.pushGlobalMessage;
            this.$req.type = "post";
            this.$req.param = {
                token: window.sessionStorage.getItem("token"),
                gameId: this.gameId,
                type: this.value[0],
                messageId: this.value[1],
            };
            const res = await this.$req.data;
            if (res.code == 0) {
                this.success("推送消息成功");
            } else {
                this.error(res.msg);
            }
        },
        async subMaintain() {
            this.$req.url = this.reqConf.setMaintain;
            this.$req.type = "post";
            this.$req.param = {
                token: window.sessionStorage.getItem("token"),
                status: this.status,
                exp: this.maintainTime
            };
            const res = await this.$req.data;
            if (res.code == 0) {
                this.success("设置维护状态成功");
            } else {
                this.error(res.msg);
            }
        }
    },
};
</script>

<style>
.selectTitle {
    font-size: 14px;
    color: #666;
    padding: 0 8px;
}

.inputText {
    font-size: 14px;
    color: #333;
}

.inputClass {
    width: 220px;
    margin-top: 10px;
}

.el-select {
    margin: 10px;
}

.maintain * {
    height: 40px;
    line-height: 40px;
    float: left;
}

.maintain .el-input {
    width: 218px;
    margin-left: 10px
}
</style>
