<template>
<div class="login_container">
    <div class="login_box">
        <!-- 头像区域 -->
        <div class="avatar_box">
            <img src="../assets/logo.png">
        </div>
        <!-- 登录表单区域 -->
        <el-form label-width="0px" class="login_from">
            <!-- 用户名 -->
            <el-form-item prop="username">
                <el-input v-model="username" prefix-icon="el-icon-user"></el-input>
            </el-form-item>
            <!-- 密码 -->
            <el-form-item prop="password">
                <el-input v-model="password" prefix-icon="el-icon-key" type="password"></el-input>
            </el-form-item>
            <!-- 按钮区域 -->
            <el-form-item class="login_buttons">
                <el-button type="primary" @click="login">登录</el-button>
                <el-button type="info" @click="resetLoginForm">重置</el-button>
            </el-form-item>
        </el-form>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            username: '',
            password: ''
        }
    },
    methods: {
        login: async function () {
            this.$req.url = this.reqConf.login;
            this.$req.type = 'post';
            this.$req.param = {
                account: this.username,
                password: this.password
            }
            try {
                const res = await this.$req.data;
                if (res.code != 0) {
                    return this.$message({
                        message: res.msg,
                        type: 'error'
                    });
                }
                window.sessionStorage.setItem("token", res.data.token);
                this.$router.push("/home");
            } catch (e) {
                console.error(e);
            }
        },
        resetLoginForm: function () {
            this.username = '';
            this.password = '';
        }
    }

}
</script>

<style lang="scss" scoped>
.login_container {
    background-color: #2b4b6b;
    height: 100%;
}

.login_box {
    width: 430px;
    height: 300px;
    background-color: #ffffff;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .avatar_box {
        height: 130px;
        width: 130px;
        border: 1px solid #eeeeee;
        border-radius: 50%;
        padding: 10px;
        box-shadow: 0 0 10px #dddddd;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #ffffff;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: #eeeeee;
        }
    }
}

.login_from {
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 0 50px;
    box-sizing: border-box;
}

.login_buttons {
    display: flex;
    justify-content: center;
}
</style>
