import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import './lib/conf.js'
import './lib/request.js'

Vue.prototype.success = function (msg) {
	return this.$message({
		message: msg,
		type: 'success'
	});
}

Vue.prototype.error = function (msg) {
	return this.$message({
		message: msg,
		type: 'error'
	});
}

Vue.config.productionTip = false
Vue.use(ElementUI)
new Vue({
	store,
	router,
	render: h => h(App)
}).$mount('#app')
