import vue from 'vue';
import axios from 'axios';
// let domain = 'http://172.22.10.12:13020';//stage 08
// let domain = 'http://172.22.10.12:13021';// aceonline
let domain = '';
// let domain = 'http://172.22.10.12:13022';//08online

switch(vue.prototype.moneyConfig.siteName){
	case "aceonline":
		domain = 'http://172.22.10.12:13021';// aceonline
		break;
	case "08online":
		domain = 'http://172.22.10.12:13022';//08online
		break;
	case "08stage":
		domain = 'http://172.22.10.12:13020';//08stage
		break;
}
/**
 * 封装的请求
 */
class request{

	/**
	 * 初始化请求默认属性
	 */

	constructor(){
		this.reqUrl=null,
		this.reqType='get',//默认方式为get
		this.reqBody={};
	}

	/**
	 * 使用属性发起请求
	 */
	start(){
		let req = this.req(this.reqUrl,this.reqBody,this.reqType);
		this._clear();//恢复默认
		return req;
	}

	/**
	 * 普通post请求
	 */
	post(url,data){
		return this.req(url,data,'post');
	}

	/**
	 * 普通get请求
	 */
	get(url,data){
		return this.req(url,data,'get');
	}

	req(url,data,type='get'){
		data = type == 'get'?{params:data}:data;//get请求处理
		return new Promise((resolve,reject)=>{
			let request = axios[type];
			//let request = type == 'get'?axios.get:axios.post;
			request(domain+url,data)
			.then(res=>{
				this._success(res,resolve);
			}).catch(error=>{
				reject(error);
			})
		});
	}

	/**
	 * 发起数据绑定请求
	 */
	async bind(vueObj){
		let reqData = await this.start();
		if(reqData.code == 403){
			window.sessionStorage.clear();
			vueObj.$router.push("/login");
			vueObj.error(reqData.msg);
			return;
		}else if(reqData.code != 0){
			vueObj.error(reqData.msg);
			return;
		}
		if(typeof(reqData.data)!="object"){
			throw new Error('绑定失败,接口返回格式不正确');
		}
		Object.assign(vueObj,reqData.data);
		return reqData;
	}

	/**
	 * 请求成功后的处理
	 */
	_success(res,resolve){
		let data = res.data;
		if(typeof(data)=='object'){
			//后端返回json时 直接返回
			resolve(data);
		}else{
			try{
				resolve(JSON.parse(data));//将字符串转换为Json后返回
			}catch(e){
				resolve(data);//转换失败后返回字符串
			}
		}
	}

	/**
	 * 将请求状态更改为默认值
	 */
	_clear(){
		this.reqUrl=null;
		this.reqType='get';
		this.reqBody={};
	}

}

/**
 * 加点语法糖
 */
class proxyConf{

	constructor(){
		this._key = {  //属性与基础方法
			'url':'reqUrl',
			'type':'reqType',
			'param':'reqBody'
		}
	}

	get (target, key, receiver) {
		for(let reKey of Object.keys(this._key)){
			if(reKey==key){
				return (value)=>{
					target[this._key[reKey]]=value;
					return receiver;
				};
			}
		}
		if(key=='data'){
			return target.start();
		}else if(key=='bind'){
			return function () {
				return target.bind.apply(target,arguments);
			};
		}else{
			return Reflect.get(target, key, receiver);
		}
	}

	set (target, key, value, receiver) {
		for(let reKey of Object.keys(this._key)){
			if(reKey==key){
				return Reflect.set(target, this._key[reKey], value, receiver);
			}
		}
		return Reflect.set(target, key, value, receiver);
	}

}

let amdRequest = new request();
let configObj = new proxyConf();
let proxy = new Proxy(amdRequest,configObj);
vue.prototype.$req = proxy;

export default proxy;