<template>
<div>
    <el-select style="width:120px" v-model="gameId" placeholder="请选择">
        <el-option v-for="item in gameList" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
    </el-select>
    <el-input style="width:200px" v-model="tableId" placeholder="桌子ID,为空表示全部桌子"></el-input>
    <el-input class="userInput" v-model="userId" placeholder="请输入玩家平台ID,为空表示全部"></el-input>
    开始时间
    <el-date-picker v-model="startDate" type="datetime" placeholder="选择统计开始时间" align="right">
    </el-date-picker>
    结束时间
    <el-date-picker v-model="endDate" type="datetime" placeholder="选择统计结束时间" align="right">
    </el-date-picker>
    <el-button @click="request" style="margin-left: 15px" type="primary">查询</el-button>
    <pubTable :table="table"></pubTable>
    <div class="block">
        <el-pagination @size-change="changeSize" @current-change="changePage" :current-page="page" :page-sizes="[10, 20, 30, 50 ,100]" :page-size="size" layout="total, sizes, prev, pager, next, jumper" :total="count">
        </el-pagination>
    </div>
</div>
</template>

<script>
export default {
    components: {
        pubTable: () => import("@/components/public/table"),
    },
    async created() {
        await this.request();
    },
    methods: {
        async request() {
            {
                this.$req.url = this.reqConf.getRoomStatDetailField;
                this.$req.type = 'post';
                this.$req.param = {
                    token: window.sessionStorage.getItem("token")
                };
                const res = await this.$req.bind(this);
                this.table.fieldData = res.data.fields;
                this.table.fieldData.splice(0,0,{
                    field: 'btn',
                    label: '查看回放',
                    button: true,
                    cb: this.viewPlayer,
                    width: 90
                });
            }
            {
                this.$req.url = this.reqConf.getRoomStatDetailData;
                this.$req.type = 'post';
                const startTime = Math.floor((+this.startDate) / 1000);
                const endTime = Math.floor((+this.endDate) / 1000);
                this.$req.param = {
                    token: window.sessionStorage.getItem("token"),
                    page: this.page,
                    size: this.size,
                    gameId: this.gameId,
                    userId: this.userId,
                    tableId: this.tableId,
                    startTime,
                    endTime
                };
                const res = await this.$req.bind(this);
                // this.table.fieldData = res.data.field;
                this.table.tableData = this.setData(res.data.list);
            }
        },
        setData(data) {
            return data.map(item => {
                item.beginTime = new Date(item.beginTime * 1000).toLocaleString("cn-GB", {
                    hour12: false
                });
                item.endTime = new Date(item.endTime * 1000).toLocaleString("cn-GB", {
                    hour12: false
                });
                return item;
            });
        },
        async changeSize(data) {
            this.size = data;
            await this.request();
        },
        async changePage(data) {
            this.page = data;
            await this.request();
        },
        async viewPlayer(data) {
            const row = data.row;
            let url = "https://08mahjongtable.livesystem168.com";
            if (this.moneyConfig.siteName == "aceonline") {
                url = "https://acemahjongtable-stage.livesystem168.com";
            }
            let path = `/?vcode=${this.moneyConfig.siteName}&apiGameId=${row.gameName}&tableUuid=${row.tableUuid}&roundLimit=${row.roundNum}`;
            window.open(url + path);
        }
    },
    data() {
        return {
            gameList: [
            //     {
            //     value: 0,
            //     label: '所有游戏'
            // }, 
            {
                value: 13,
                label: '十三水'
            }, {
                value: 15,
                label: '麻将'
            }],
            gameId: 13,
            tableId: '',
            startDate: new Date().setHours(0, 0, 0, 0),
            endDate: new Date(),
            count: 100,
            page: 1,
            size: 20,
            userId: "",
            table: {
                summary: true,
                fieldData: [],
                tableData: []
            }
        }
    },
};
</script>

<style scoped>
.block {
    margin-top: 25px;
}
</style>
